import * as React from "react"
import {useState} from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"
import {FaBars, FaTimes} from 'react-icons/fa';
import {Transition} from '@headlessui/react'

const Header = ({siteTitle, siteMenu}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    let classes = 'font-bold text-sm md:text-base hover:text-gray-900 hover:underline hover:decoration-red-600'

    return (<>
        <header className={"max-w-5xl px-6 lg:py-10 py-6 mx-auto w-full transition"}>
            <div className={'grid sm:block grid-cols-2'}>
                <div className={'col-span-1'}>
                    <Link to="/"
                          className={"break-words w-fit text-slate-800 whitespace-normal text-base md:text-xl lg:text-2xl font-bold mb-1 hover:underline hover:decoration-red-600"}
                          style={{wordSpacing: '0.1rem'}}>
                        {siteTitle}
                    </Link></div>
                <div className={'ml-auto col-span-1 sm:hidden h-5'}>
                    <Transition show={!menuOpen}>
                        <FaBars className={'hover:cursor-pointer'} onClick={() => setMenuOpen(true)}/>
                    </Transition>
                    <Transition show={menuOpen}>
                        <FaTimes className={'hover:cursor-pointer'} onClick={() => setMenuOpen(false)}/>
                    </Transition>
                </div>
            </div>
            <nav>
                <ul className={"my-2 hidden sm:flex"}>
                    {siteMenu.map(p => (<li className="mr-6" key={p.title}>
                        <Link
                            to={p.url}
                            getProps={(p) => {
                                return {
                                    className: p.isPartiallyCurrent ? 'text-gray-800 underline ' + classes : classes + " text-gray-600 "
                                }
                            }}
                        >
                            {p.title}
                        </Link>
                    </li>))}
                </ul>
            </nav>

            <div
                className={`transition-all block sm:hidden duration-2000-mx-6 ease-in-out ${menuOpen ? "max-h-full h-16" : "max-h-0 h-0"}`}>
                <ul className={`mx-auto gap-y-2 flex flex-col justify-center text-center ${menuOpen ? "mt-8 visible" : "invisible"}`}>
                    {siteMenu.map(p => (<li key={p.title}>
                        <a
                            href={p.url}
                            className={"break-words w-fit text-slate-800 whitespace-normal text-base font-bold mb-1 hover:underline hover:decoration-red-600"}
                        >
                            {p.title}
                        </a>
                    </li>))}
                </ul>
            </div>
        </header>
    </>)
}

Header.propTypes = {
    siteTitle: PropTypes.string, siteMenu: PropTypes.array,
}

Header.defaultProps = {
    siteTitle: ``, siteMenu: [],
}

export default Header
