import * as React from "react"

const Section = ({children, sectionColour = null, padding = "py-6", classes = ""}) => {
    return (
        <section className={(sectionColour ?? 'bg-white') + (" " + classes ?? null)}>
            <div className={`max-w-5xl px-6 ${padding} mx-auto`}>
                {children}
            </div>
        </section>
    )
}

export default Section
