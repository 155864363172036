import * as React from "react"
import {Link} from "gatsby"
import Colour from "../types/colours";
import {FaShareSquare} from 'react-icons/fa'

const Footer = (props) => {
    const share = () => {
        try {
            navigator.share({
                title: document.title,
                url: new URL(window.location.pathname, props.siteUrl).toString(),
                text: "Keith Wales Window Cleaning"
            }).catch(console.log);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <footer className={`border-t border-gray-100 bg-gray-50 text-gray-900`}>
            <div className={'max-w-5xl px-6 py-14 mx-auto'}>
                <div className={`md:flex`}>
                    <div className={'md:w-8/12'}>
                        <div className={"justify-self-start"}>
                            <ul className={'list'}>
                                {props.footerMenu.map(p => (
                                    <li key={p.title}><Link className={'hover:underline hover:decoration-red-600'}
                                                            to={p.url}>{p.title}</Link></li>
                                ))}
                                <li>
                                    <button
                                        className={'link hover:underline decoration-2 hover:decoration-red-600'}
                                        onClick={() => share()}><span className={'flex'}>Share <FaShareSquare/></span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={`my-2 md:my-0 md:w-4/12 md:justify-end`}>
                        <div>
                            © {new Date().getFullYear()} {props.siteTitle}
                        </div>


                    </div>
                </div>
            </div>
            <div className={`justify-center flex py-4 whitespace-normal font-xs md:font-base px-6 ${Colour.grad}`}>
                <div>Website developed and maintained by{' '}
                    <a className={'whitespace-pre hover:underline hover:decoration-red-600 '}
                       href={'https://chriswales.uk'}>Christopher Wales</a></div>
            </div>
        </footer>
    )
}

export default Footer
