const enum Colour {
    Grey = `bg-slate-200`,
    Gray = `bg-slate-200`,
    Blue = `bg-blue-200`,
    BluePrimary = 'bg-blue-600',
    Red = `bg-red-200`,
    smokeWhite = 'bg-slate-50',
    grad = 'bg-gradient-to-r from-slate-100 to-pink-100',
    herograd = 'bg-gradient-to-tr from-slate-50 via-white to-gray-100'
}

export default Colour;