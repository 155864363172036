/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Header from "./header";
import Footer from "./footer";
import {useLocation} from '@reach/router';

const Accent = () => {
    return <span className={"block border-t-4 border-red-800 w-1/3"}/>
}

const Layout = ({children}) => {
    const location = useLocation();
    const data = useStaticQuery(graphql`
  query MyQuery {
    site {
      siteMetadata {
       title
       siteUrl
        mainMenu {
          title
          url
        },
        footerMenu {
          title
          url
        }
      }
    }
  }  
  `);

    return (
        <>

            <div className={'flex flex-col min-h-screen max-w-full w-full'}>

                <Header siteTitle={data.site.siteMetadata?.title || `Title`}
                        siteMenu={data.site.siteMetadata?.mainMenu}/>
                <noscript
                    className={`bg-orange-100 px-2 py-4 px-auto text-center ${location.pathname !== "/" ? "mb-3" : ""}`}>This
                    site works best with JavaScript
                    enabled. Please enable JavaScript via your browser's
                    settings.
                </noscript>

                <main className={'grow mx-0'}>
                    {children}
                </main>

                <Footer siteTitle={data.site.siteMetadata?.title} footerMenu={data.site.siteMetadata?.footerMenu}
                        siteUrl={data.site.siteMetadata?.siteUrl}/>

            </div>
        </>
    )
}


export {Layout, Accent};
